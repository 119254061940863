import React from 'react';
import Header from './components/layout/Header';
import snowglobeLogo from './images/Snowglobe-logo.svg';
import snowShelf from './images/snow-shelf.svg';
import BambooButton from './components/layout/BambooButton';
import Accordion from './components/layout/Accordion';
import './styles/global.css';
import savageSafari from './images/s3.svg';

function App() {
  return (
    <div className="app-container">
      <Header />
      <main>
        <section id="home" className="section">
          <img src={savageSafari} alt="Savage Safari Logo" className="home-logo" />
        </section>
        
        <section id="about" className="section">
          <Accordion />
          
          <div className="button-wrapper">
            <BambooButton />
          </div>
        </section>
      </main>
      <footer>
        <div className="footer-section">
          <p className="section-headline">Questions?</p>
          <a 
            href="https://instagram.com/direct/t/savageseastiki"
            onClick={(e) => {
              e.preventDefault();
              
              // Try multiple deep linking formats for better iOS compatibility
              const urls = [
                'instagram://direct/t/savageseastiki',
                'instagram://direct/inbox/t/savageseastiki',
                'instagram://user?username=savageseastiki',
                'https://instagram.com/direct/t/savageseastiki'
              ];

              // Try to open app with each URL format
              let urlIndex = 0;
              const tryNextUrl = () => {
                if (urlIndex < urls.length) {
                  window.location.href = urls[urlIndex];
                  urlIndex++;
                  setTimeout(tryNextUrl, 25);
                }
              };

              tryNextUrl();
            }}
            className="instagram-dm-button"
          >
            Message Us
          </a>
          <p className="questions-text">or DM us @savageseastiki</p>
        </div>

        <div className="footer-section">
          <p className="section-headline">SOCIALS</p>
          <div className="social-icons">
            <a href="https://www.instagram.com/savageseastiki/" target="_blank" rel="noopener noreferrer">
              <div className="circle-icon instagram"></div>
            </a>
          </div>
        </div>

        <p className="copyright">&copy; {new Date().getFullYear()} Savage Seas Tiki. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App; 